export const routes = [
    {
        path: '/',
        component: () => import(/* webpackChunkName: "Common" */ './layouts/index.vue'),
        children: [
            // 홈
            {
                path: '/',
                component: () => import(/* webpackChunkName: "Common" */ '@/views/home.vue')
            },

            // 소개
            {
                path: 'introduce',
                component: () => import(/* webpackChunkName: "Common" */ '@/views/introduce.vue')
            },

            // 업무분야
            {
                path: 'law',
                component: () => import(/* webpackChunkName: "Common" */ '@/views/law.vue')
            },

            // 구성원
            {
                path: 'lawyer',
                component: () => import(/* webpackChunkName: "Common" */ '@/views/lawyer.vue')
            },

            // 오시는 길
            {
                path: 'location',
                component: () => import(/* webpackChunkName: "Common" */ '@/views/location.vue')
            }
        ]
    }
]