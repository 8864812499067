import Vue from 'vue'
import Vuetify from 'vuetify'
import 'vuetify/dist/vuetify.min.css'

Vue.use(Vuetify)

export default new Vuetify({
    theme: {
        themes: {
            // 기존 값
            light: {
                primary: "#165973", 
                secondary: "#2d76a7",
                third: "#323e82"
            }
        }
    }, 
})
