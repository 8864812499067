import Vue from 'vue'
import App from './App.vue'
import Router from 'vue-router'
import { routes } from './routes.js'
import vuetify from './plugins/vuetify'
import '@babel/polyfill'

// 네이버 지도
import naver from 'vue-naver-maps'
Vue.use(naver, {
    clientID: '7glm7hk1t9',
    useGovAPI: false, //공공 클라우드 API 사용 (선택)
    subModules:'' // 서브모듈 (선택)
})

// 모션
import AOS from 'aos'
import "aos/dist/aos.css"

Vue.use(Router)

Vue.config.productionTip = false

export const eventBus = new Vue()

const router = new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
    // 항상 위로
    // scrollBehavior(to, from, savedPosition) {
    //     return { x: 0, y: 0 };
    // },
})

new Vue({
    router,
    vuetify,

    // 모션
    created() {
        AOS.init({
            duration: 1000,
            once: true
        })
    },
    render: h => h(App)
}).$mount('#app')