<template>
    <v-app>
        <router-view :key="$route.fullPath"></router-view>

        <v-btn
            v-if="$vuetify.breakpoint.mobile"
            color="primary"
            fab
            style="position:fixed; bottom:20px; right:20px;"
            @click="Call()"
        >
            <v-icon color="white">mdi-phone-in-talk-outline</v-icon>
        </v-btn>
    </v-app>
</template>
<script>
export default {
    name: 'App',

    methods: {
        Call(){
            window.location.href = 'tel:0234765016'
        }
    }
}
</script>
<style>
.no_drag{
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */
}
</style>